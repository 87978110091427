export const styles = [
  {
    featureType: "landscape",
    elementType: "all",
    stylers: [
      {
        hue: "#FFAD00"
      },
      {
        saturation: 50.2
      },
      {
        lightness: -34.8
      },
      {
        gamma: 1
      }
    ]
  },
  {
    featureType: "landscape.natural.landcover",
    elementType: "geometry.fill",
    stylers: [
      {
        color: "#cbb42e"
      },
      {
        visibility: "on"
      }
    ]
  },
  {
    featureType: "poi",
    elementType: "all",
    stylers: [
      {
        hue: "#FFC300"
      },
      {
        saturation: 54.2
      },
      {
        lightness: -14.4
      },
      {
        gamma: 1
      }
    ]
  },
  {
    featureType: "road.highway",
    elementType: "all",
    stylers: [
      {
        hue: "#FFAD00"
      },
      {
        saturation: -19.8
      },
      {
        lightness: -1.8
      },
      {
        gamma: 1
      }
    ]
  },
  {
    featureType: "road.arterial",
    elementType: "all",
    stylers: [
      {
        hue: "#FFAD00"
      },
      {
        saturation: 72.4
      },
      {
        lightness: -32.6
      },
      {
        gamma: 1
      }
    ]
  },
  {
    featureType: "road.local",
    elementType: "all",
    stylers: [
      {
        hue: "#FFAD00"
      },
      {
        saturation: 74.4
      },
      {
        lightness: -18
      },
      {
        gamma: 1
      }
    ]
  },
  {
    featureType: "water",
    elementType: "all",
    stylers: [
      {
        hue: "#00FFA6"
      },
      {
        saturation: -63.2
      },
      {
        lightness: 38
      },
      {
        gamma: 1
      }
    ]
  },
  {
    featureType: "water",
    elementType: "geometry.fill",
    stylers: [
      {
        visibility: "on"
      },
      {
        color: "#ffe59c"
      }
    ]
  }
];
